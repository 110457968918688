export enum SocketType {
    CHAT = 'chat',
    CHATS = 'chats',
    INVITES = 'invites',
    CAMERA = 'camera',
    VIDEOS = 'videos',
    PURCHASES = 'purchases',
}

export enum SocketState {
    CONNECT = 'connect',
    DISCONNECT = 'disconnect',
    RECONNECT = 'reconnect',
}

export enum ChatsRoom {
    JOIN_CHATS = 'join-chats',
    LEAVE_CHATS = 'leave-chats',
    CHATS = 'chats',
}

export enum ChatRoom {
    JOIN_CHAT = 'join-chat',
    LEAVE_CHAT = 'leave-chat',
    CHAT = 'chat',
}

export enum InviteRoom {
    JOIN_TO_INVITES = 'joinToInvites',
    LEAVE_FROM_INVITES = 'leaveFromInvites',
    USER_ADDED = 'invite:user-added',
    CREATED = 'invite:created',
    UPDATED = 'invite:updated',
    REMOVED = 'invite:removed',
}

export enum VideosRoom {
    USER_CONNECTED = 'userConnected',
    USER_DISCONNECTED = 'userDisconnected',
    SCHOOL_RECORDER_CONNECTED = 'schoolRecorderConnected',
}

export enum CameraRoom {
    START_CAMERA = 'startCamera',
    STOP_CAMERA = 'stopCamera',
    CANCEL_AUTO_EVENT = 'cancelAutoEvent',

    UPDATE_CAMERA = 'updateCamera',
    UPDATE_IMAGE_ON_CAMERA = 'updateImageOnCamera',
    AUTO_UPDATE_CAMERA = 'autoUpdateCamera',
}

export enum PurchasesRoom {
    JOIN_PURCHASE_PAGE = 'joinPurchasePage',
    LEAVE_PURCHASE_PAGE = 'leavePurchasePage',
    ONLINE_PAYMENT_UPDATED = 'onlinePaymentUpdated',
}
