import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { GlobalService } from '../global/global.service';

export interface Response<T = any> {
    success: boolean;
    response?: T;
}

@Injectable()
export class HttpService {
    public headers = new HttpHeaders();
    public options = { headers: this.headers, withCredentials: true };

    constructor(
        private http: HttpClient,
        private globalService: GlobalService,
    ) {}

    public get(url: string, options?): Observable<any> {
        const { full_url, payload } = this.getUrlAndPayload(url, options);

        return this.request('get', [full_url, { ...this.options, ...payload }]);
    }

    public post(url: string, data: Object, options?): Observable<any> {
        const { full_url, payload } = this.getUrlAndPayload(url, options);

        return this.request('post', [
            full_url,
            data,
            { ...this.options, ...payload },
        ]);
    }

    public put(url: string, data: Object, options?): Observable<any> {
        const { full_url, payload } = this.getUrlAndPayload(url, options);

        return this.request('put', [
            full_url,
            data,
            { ...this.options, ...payload },
        ]);
    }

    public delete(url: string, options?): Observable<any> {
        const { full_url, payload } = this.getUrlAndPayload(url, options);

        return this.request('delete', [
            full_url,
            { ...this.options, ...payload },
        ]);
    }

    public request(type: string, params: any[]): Observable<any> {
        this.globalService.addRequest(params[0], type);

        return this.http[type](...params).pipe(
            map((d) => {
                this.globalService.removeRequest(params[0], type);
                return d;
            }),
            catchError((err) => {
                this.globalService.removeRequest(params[0], type);
                return throwError(err);
            }),
        );
    }

    public encodeParams(params: { [key: string]: string }): string {
        return Object.keys(params).reduce((acc, key) => {
            const param = `${key}=${encodeURIComponent(params[key])}`;

            acc += !acc ? `?${param}` : `&${param}`;

            return acc;
        }, '');
    }

    private getUrlAndPayload(
        url: string,
        options: any = {},
    ): { full_url: string; payload: any } {
        const { base_url = environment.apiUrl, ...payload } = options;
        const full_url = `${base_url}${url}`;

        return { full_url, payload };
    }
}
