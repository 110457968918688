export enum Constants {
    PERCENT = 100,
    DEBOUNCE = 500,
    SEARCH_MIN_LENGTH = 2,
    SEARCH_COMPANY_MIN_LENGTH = 1,
    ONE_OF_THREE = 3,
    COMMENT_MIN_LENGTH = 10,
    COMMENT_MAX_LENGTH = 500,
    MAX_DATES_IN_GRAPH = 22,
    SUNDAY = 7,
    FINE_SIZE = 20,
    WORK_TIME_MIN = 0.1,
    WORK_TIME_MAX = 24,
    INVITE_EXPIRED_DAYS = 20,
    FULL_PLACE_COUNT_IN_CLASS = 15,
    DAYS_OF_MONTH = 30,
    AROUND_DAYS = 0.5,
    AUTO_RELOAD_TIMEOUT = 30000,
    AUTO_RESET_TIMEOUT = 5000,
    RESERVATION_DAYS = 5,
    CASHBOX_EDIT_HOUR = 11,
    CASHBOX_EDIT_HOURS = 35,
    COUPONS_LENGTH = 15,
    PAGINATOR_PAGE_SIZE = 50,
    MILLISECONDS_IN_HOUR = 3600000,
    ROUTER_HISTORY_BREAK = -2,
    ABSOLUTE_YEAR = 1970,
    NOT_AUTHORIZED = 401,
    GROUP_ID_LENGTH = 24,
    EMAIL_EXISTS = 409,
    MINUTES = 60,
    REFERRALS_LIMIT = 40,
    SKILLS_LIMIT = 40,
    SKILLS_NAME_LENGTH = 50,
    VPOS_LIMIT = 40,
    PAYMENTS_LIMIT = 30,
    PAGINATOR_LIMIT = 50,
    MAX_LENGTH_DE_NUMBER = 11,
    MIN_LENGTH_DE_NUMBER = 10,
    MAX_LENGTH_BG_NUMBER = 9,
    MIN_LENGTH_BG_NUMBER = 8,
    MAX_LENGTH_AT_NUMBER = 11,
    MIN_LENGTH_AT_NUMBER = 9,
    MAX_FILE_SIZE = 52428800,
    LIMIT = 40,
    MIN_NAME = 2,
    MAX_NAME = 64,
}

export enum UserFilters {
    GROUPS_PAGE_FILTER = 'groups_page_filter',
    RESERVES_PAGE_FILTER = 'reserve_page_filter',
    COACHES_PAGE_FILTER = 'coaches_page_filter',
    INTERVIEWS_PAGE_FILTER = 'interviews_page_filter',
    CASHBOX_PAGE_FILTER = 'cashbox_page_filter',
    SUPPORT_CHATS = 'support_chats',
}

export enum Timer {
    ONE_SECOND_MILI = 1000,
    FIVE_MINUTES = 5,
}

export enum Schedule {
    MAX_CONFLICT_CARDS = 9,
    CONFLICT_CARD_TOP = 20,
    CARD_HEIGHT = 64,
}

export enum Rating {
    EXCELLENT_LEVEL = 90,
    VERY_GOOD_LEVEL = 70,
    GOOD_LEVEL = 50,
}

export enum RatingPositions {
    FIRST = 1,
    SECOND = 2,
    THIRD = 3,
}

export enum RatingDiscountsMoney {
    FIRST = 3000,
    SECOND = 2500,
    THIRD = 2000,
    ALL = 1000,
}

export enum RatingDiscountsPercent {
    FIRST = 25,
    SECOND = 15,
    THIRD = 10,
    ALL = 5,
}

export enum Declension {
    HUNDREDS = 100,
    DOZENS = 10,
    BIGGER = 5,
    SMALLER = 20,
}

export enum IntlTelValidation {
    ZERO,
    ONE,
    TWO,
    THREE,
    FOUR,
}

export enum CoachStatistic {
    NPS_LOW = 80,
    RATING_LOW = 60,
    RATING_HIGH = 75,
    TIME_FILLING_LOW = 50,
    TIME_FILLING_HIGH = 70,
    ASSESSED_LOW = 50,
    ASSESSED_HIGH = 70,
    QUALITY_LOW = 70,
    QUALITY_HIGH = 80,
    VISITS_LOW = 70,
    VISITS_HIGH = 80,
    HOMEWORKS_LOW = 55,
    HOMEWORKS_HIGH = 75,
}

export const MOMENT_FORMAT = 'YYYY-MM-DD[T00:00:00.000Z]';
